import { Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, Tag, Spinner } from "@chakra-ui/react"
import { EquipitUser } from "../models/user"
import { useFetch } from "../contexts/FetchContext"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

type UserTagProps = {
  email: string
}

export const UserTag = ({email}: UserTagProps) => {

  const { backend } = useFetch()
  const [user, setUser] = useState<EquipitUser>()
  const [error, setError] = useState<string>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (isOpen && !user) {
      backend.users.getAll(email).then((users: EquipitUser[]) => {
        for (let i = 0; i < users.length; i++) {
          if (users[i].email === email) {
            setUser(users[i])
            return
          }
        }
        setUser({ displayName: "Unknown", company: "Unknown" } as EquipitUser)
      }).catch((err) => {
        setError(err)
      })
    }
  }, [email, isOpen])

  const PopoverUser = useMemo(() => {
    if (user) {
      return (
        <>
          <PopoverHeader>{user.displayName}</PopoverHeader>
          <PopoverBody>{user.company}</PopoverBody>
        </>
      )
    }else{
      return(
        <>
          <PopoverHeader>{error}</PopoverHeader>
          <PopoverBody>
            <Spinner />
          </PopoverBody>
        </>
      )
    }
  }, [user, error])

  return (
    <Popover isOpen={isOpen}>
      <PopoverTrigger>
        <Tag
          colorScheme='blue'
          onMouseLeave={() => setIsOpen(false)}
          onMouseOver={() => setIsOpen(true)}
          onClick={(e) => {e.stopPropagation(); navigate(`/user/${email}`)}}
        >{email}</Tag>
      </PopoverTrigger>
      <PopoverContent>
        {PopoverUser}
      </PopoverContent>
    </Popover>
  )
}
