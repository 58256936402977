import React, { useContext, useMemo } from 'react';
import { IconButton, ButtonGroup, Tooltip, Input, Select, Text, VStack, WrapItem, Switch, Button } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { EditIcon } from '@chakra-ui/icons';

import { TableData } from '../../components/TableData';
import { AppContext } from '../../contexts/AppContext';
import { useFetch } from '../../contexts/FetchContext';
import { User } from '../../models/roles';
import { MultiSelect } from '../../components/MultiSelect';

interface RoleOption {
  key: string;
  label: string;
}

export const UserTable = () => {
  const app = useContext(AppContext);
  const { backend } = useFetch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchItems = (params: any) => backend.role.getAllUsers(params);

  const allRoles: RoleOption[] = [
    { key: 'Admin', label: "Admin" },
    { key: 'User', label: "User" },
    { key: 'Moderator', label: "Moderator" },
    { key: 'Receptionist', label: "Receptionist" },
  ];



  const buttonGroup = (user: User) => {
    return (
      <ButtonGroup variant="ghost">
        <Tooltip label="Edit">
          <IconButton
            aria-label="edit"
            icon={<EditIcon />}
            onClick={(event) => {
              event.stopPropagation();
              navigate(`/users/${user.email}/edit`, { state: { previousLocation: location.pathname } });
            }}
          />
        </Tooltip>
      </ButtonGroup>
    );
  };


  const onRowClick = (user: User) => {
    navigate(`/user/${user.email}`);
  };

  const columns: ColumnDef<User>[] = [
    {
      id: 'name',
      accessorFn: row => row.name,
      header: 'Name',
      enableHiding: true,
    },
    {
      id: 'email',
      accessorFn: row => row.email,
      header: 'Email',
      enableHiding: true,
    },
    {
      id: 'company',
      accessorFn: row => row.company,
      header: 'Company',
      enableHiding: true,
    },
    {
      id: 'location',
      accessorFn: row => row.location,
      header: 'Location',
      enableHiding: true,
    },
    {
      id: 'mobile',
      accessorFn: row => row.mobile,
      header: 'Mobile',
      enableHiding: true,
    },
    {
      id: 'userDisabled',
      accessorFn: row => row.isDisabled ? 'Yes' : 'No',
      header: 'Disabled',
      enableHiding: true,
    },
    {
      id: 'roles',
      accessorFn: row => row.roles.map(role => role.name).join(', '),
      header: 'Roles',
      enableHiding: true,
    },
  ];


  const additionalFilters = useMemo(() => (
    <>
      <WrapItem as={VStack} align="left">
        <Text fontWeight="bold">Search</Text>
        <Input
          placeholder="Search for users"
          w="300px"
          value={searchParams.get('query') || ''}
          onChange={(e) => {
            const newParams = new URLSearchParams(searchParams);
            if (e.target.value) {
              newParams.set('query', e.target.value);
            } else {
              newParams.delete('query');
            }
            setSearchParams(newParams);
          }}
        />
      </WrapItem>
      <WrapItem as={VStack} align="left">
        <Text fontWeight="bold">Role</Text>
        <Select
          value={searchParams.get('role') || ''}
          onChange={(e) => {
            const newParams = new URLSearchParams(searchParams);
            if (e.target.value) {
              newParams.set('role', e.target.value);
            } else {
              newParams.delete('role');
            }
            setSearchParams(newParams);
          }}
        >
          <option value="">All Roles</option>
          {allRoles.map(role => (
            <option key={role.key} value={role.key}>{role.label}</option>
          ))}
        </Select>
      </WrapItem>
      <WrapItem as={VStack} align="left">
        <Text fontWeight="bold">Include No Role</Text>
        <Switch
          isChecked={searchParams.get('includeNoRole') === 'true'}
          onChange={(e) => {
            const newParams = new URLSearchParams(searchParams);
            if (e.target.checked) {
              newParams.set('includeNoRole', 'true');
            } else {
              newParams.delete('includeNoRole');
            }
            setSearchParams(newParams);
          }}
        />
      </WrapItem>
    </>
  ), [searchParams, setSearchParams, allRoles]);

  return (
    <>
      <TableData<User>
        title="Users"
        fetchItems={fetchItems}
        columns={columns}
        initialSortColumn="name"
        sortDesc={false}
        statusOptions={[]}
        onRowClick={onRowClick}
        searchParams={searchParams}
        additionalFilters={additionalFilters}
        buttonGroup={buttonGroup}
      />
      <Outlet />
    </>
  );
};