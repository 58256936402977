import { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { Tag, ButtonGroup, Stack, Button, Input, VStack, Text, useColorModeValue, WrapItem, Wrap, Switch } from '@chakra-ui/react';
import { ColumnDef, InitialTableState } from '@tanstack/react-table';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useFetch } from '../../contexts/FetchContext';
import { AppContext } from '../../contexts/AppContext';
import { TanstackTable } from '../../components/TanstackTable';
import { MinusIcon, CheckIcon } from '@chakra-ui/icons';
import { JetBrainLicense, License, LicenseGroup, licenseStatusEnum } from '../../models/newLicenses';
import { UserTag } from '../../components/UserTag';
import { MultiSelect, MultiSelectOption } from '../../components/MultiSelect';

interface Parameters {
  key: string,
  value: any
}

// Table rows take both License and JetBrainLicense types
export const LicenseTable = () => {

  const statusTodo = [licenseStatusEnum.NEW_IN_AD, licenseStatusEnum.DELETED_IN_AD]

  const [licenses, setLicenses] = useState<(License | JetBrainLicense)[]>([]);
  const [showTodo, setShowTodo] = useState<boolean>(true)
  const [licenseGroups, setLicenseGroups] = useState<LicenseGroup[]>([]);
  const [licenseGroupKeys, setLicenseGroupKeys] = useState<string[]>([])
  const [status, setStatus] = useState<licenseStatusEnum[]>(statusTodo)
  const [page, setPage] = useState<number>(1)
  const app = useContext(AppContext);
  const loader = useRef(null);
  const { backend } = useFetch()

  const navigation = useNavigate()
  const location = useLocation()
  const bodyBg = useColorModeValue('white', "gray.900")

  const statusDone = [licenseStatusEnum.ACTIVE, licenseStatusEnum.DELETED]

  const PAGESIZE = 20;

  const STATUS_OPTIONS: MultiSelectOption[] = [
    { key: '0', label: 'New in AD', colorScheme: 'green' },
    { key: '1', label: 'Deleted in AD', colorScheme: 'red' },
    { key: '2', label: 'Deleted', colorScheme: 'purple' },
    { key: '3', label: 'Active', colorScheme: 'blue' },
    { key: '4', label: 'Unassigned', colorScheme: 'cyan' }
  ]

  const JETBRAIN_OPTION: LicenseGroup = {
    id: 0, // ID doesnt exist in DB
    displayName: "JetBrains",
    licenseKey: "jetbrains",
    active: true,
  }

  const [params, setParams] = useState<{ [x: string]: any }>({
    LicenseStatuses: statusTodo,
    LicenseGroupKeys: licenseGroupKeys,
    page: page,
    pageSize: PAGESIZE
  });

  const updateParams = (parameters: Parameters[]) => {
    const newParameters = parameters.reduce((acc, parameter) => {
      acc[parameter.key] = parameter.value;
      return acc;
    }, {} as Record<string, any>);
    setParams(prevParams => ({ ...prevParams, ...newParameters }))
    setPage(1);
  }

  app.setTitle("Licenses")

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage(prevPage => prevPage + 1);
        }
      },
      { root: null, rootMargin: '20px', threshold: 1.0 }
    );

    if (loader.current) observer.observe(loader.current);

    return () => {
      if (loader.current) observer.unobserve(loader.current);
    };
  }, []);

  useEffect(() => {
    if (showTodo) {
      updateParams([{ key: 'LicenseStatuses', value: statusTodo }])
    }
    else {
      updateParams([{ key: 'LicenseStatuses', value: [statusTodo, statusDone].flat() }])
    }
  }, [showTodo])

  useEffect(() => {
    updateParams([{ key: 'LicenseStatuses', value: status }])
  }, [status])

  useEffect(() => {
    updateParams([{ key: 'LicenseGroupKeys', value: licenseGroupKeys }])
  }, [licenseGroupKeys])

  useEffect(() => {
    refetch()
  }, [location, params, page])

  const refetch = async () => {
    backend.licenseGroups.getAll().then((licenseGroups: LicenseGroup[]) => {
      setLicenseGroups([JETBRAIN_OPTION, ...licenseGroups])
    }).catch((err: any) => {
      console.log(err)
    })
    try {
      const regularLicenses = await backend.licenses.getAll({ ...params, page: page, pageSize: PAGESIZE })
      setLicenses(prevLicenses => page === 1 ? regularLicenses : [...prevLicenses, ...regularLicenses]);
      const jetBrainLicenses = await backend.jetBrainsLicenses.getAll({ ...params, page: page, pageSize: PAGESIZE })
      setLicenses(prevLicenses => page === 1 ? [...regularLicenses, ...jetBrainLicenses] : [...prevLicenses, ...regularLicenses, ...jetBrainLicenses]);
    } catch (err) {
      console.log(err);
    }
  }

  const tableData = useMemo(() => {
    return licenses
  }, [licenses])

  const initialTableState: InitialTableState = {
    sorting: [{ id: 'id', desc: true }],
    columnVisibility: {
      'computerId': false
    }
  };

  const filterContainer = () => {
    return (
      <Wrap
        bg={bodyBg}
        borderRadius={6}
        shadow='md'
        p={4}
        w="100%"
        spacing={4}
        marginBottom={4}
        align="flex-end"
        justifyItems='stretch'
      >
        <WrapItem
          as={VStack}
          align="left"
        >
          <Text fontWeight="bold">Search</Text>
          <Input
            placeholder='Search for user'
            w="300px"
            onChange={(e) => updateParams([{ key: 'query', value: e.target.value }])}
          />
        </WrapItem>
        <WrapItem
          as={VStack}
          align="left"
        >
          <Text fontWeight="bold">Only To-Do</Text>
          <Switch
            id='show_all'
            isChecked={showTodo}
            onChange={(e) => e.currentTarget.checked === true ? setShowTodo(true) : setShowTodo(false)}
          />
        </WrapItem>
        <WrapItem
          as={VStack}
          align="left"
        >
          <Text fontWeight="bold">License Groups</Text>
          <MultiSelect
            storageKey="licenseTableColumnVisibility"
            options={
              licenseGroups.map((licenseGroup: LicenseGroup) => ({
                key: licenseGroup.licenseKey,
                label: licenseGroup.displayName,
                colorScheme: 'blue'
              }))
            }
            onChange={(selected) => {
              const selectedKeys = selected.map((item: any) => item.key)
              setLicenseGroupKeys(selectedKeys)
            }}
          />
        </WrapItem>
        <WrapItem
          as={VStack}
          align="left"
        >
          <Text fontWeight="bold">Status</Text>
          <MultiSelect
            options={STATUS_OPTIONS}
            onChange={(selected) => {
              const selectedKeys = selected.map((item: any) => item.key)
              setStatus(selectedKeys)
            }}
          />
        </WrapItem>
        <WrapItem
          as={VStack}
          align="left"
        >
        </WrapItem>
      </Wrap>
    )
  }

  const getLicenseDisplayName = (licenseGroupKey: string) => {
    const licenseGroup = licenseGroups.find((licenseGroup: LicenseGroup) => licenseGroup.licenseKey === licenseGroupKey)
    if (licenseGroup) {
      return licenseGroup.displayName
    }
    return 'Unknown'
  }

  const buttonGroup = (status: licenseStatusEnum, userId: number) => {
    const actionButton = () => {
      if (status === licenseStatusEnum.NEW_IN_AD) {
        return <Button
          aria-label="add"
          leftIcon={<CheckIcon />}
          onClick={(event) => { event.stopPropagation(); navigation(`${userId}/add`, { state: { previousLocation: location.pathname } }) }}
        >
          Added
        </Button>
      } else if (status === licenseStatusEnum.DELETED_IN_AD) {
        return <Button
          aria-label="delete"
          colorScheme='red'
          leftIcon={<MinusIcon />}
          onClick={(event) => {
            event.stopPropagation();
            navigation(`/licenses/${userId}/delete`, { state: { previousLocation: location.pathname } })
          }}>
          Remove
        </Button>
      }
    }
    return (
      <ButtonGroup variant="ghost">
        {actionButton()}
      </ButtonGroup>
    )
  }


  const statusTags = (status: licenseStatusEnum) => {
    // This is how DB is set up;
    // LicenseStatus : int (0, 1, 2, 3, 4)
    // 0: Deleted
    // 1: New in AD
    // 2: Deleted in AD
    // 3: Active
    // 4: Unassigned
    switch (status) {
      case licenseStatusEnum.NEW_IN_AD:
        return <Tag colorScheme='green'>New in AD</Tag>
      case licenseStatusEnum.DELETED_IN_AD:
        return <Tag colorScheme='red'>Deleted in AD</Tag>
      case licenseStatusEnum.DELETED:
        return <Tag colorScheme='purple'>Deleted</Tag>
      case licenseStatusEnum.ACTIVE:
        return <Tag colorScheme='blue'>Active</Tag>
      case licenseStatusEnum.UNASSIGNED:
        return <Tag colorScheme='cyan'>Unassigned</Tag>
      default:
        console.log(status, "Invalid status")
        throw new Error("Invalid status")
    }
  }

  // Table rows take both License and JetBrainLicense types
  const columns: ColumnDef<License | JetBrainLicense>[] = [
    {
      id: 'licenseGroupKey',
      accessorFn: row => row.licenseGroupKey,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'License Group',
      cell: (info) => 'licenseGroupKey' in info.row.original ? getLicenseDisplayName(info.row.original.licenseGroupKey) : null
    },
    {
      id: 'company',
      accessorFn: row => 'teamName' in row ? row.teamName : null,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Company',
      cell: (info) => 'teamName' in info.row.original ? info.row.original.teamName : null
    },
    {
      id: 'email',
      accessorFn: row => row.userEmail,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Email',
      cell: (info) => 'userEmail' in info.row.original ? UserTag({ email: info.row.original.userEmail }) : null
    },
    {
      id: 'productName',
      accessorFn: row => 'productName' in row ? row.productName : null,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Product Name',
      cell: (info) => 'productName' in info.row.original ? info.row.original.productName : null
    },
    {
      id: 'status',
      accessorFn: row => row.licenseStatus,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Status',
      cell: info => info.row.original.licenseStatus !== null ? statusTags(info.row.original.licenseStatus) : null
    },
    {
      id: 'validUntil',
      accessorFn: row => 'validUntil' in row ? row.validUntil : null,
      sortingFn: 'auto',
      enableHiding: true,
      header: 'Valid Until',
      cell: info => 'validUntil' in info.row.original ? info.row.original.validUntil : null
    },
    {
      id: 'actions',
      header: 'Actions',
      sortingFn: 'auto',
      enableHiding: false,
      cell: info => buttonGroup(info.row.original.licenseStatus, info.row.original.id),
      meta: {
        isNumeric: true
      }
    },
  ]

  useEffect(() => {
  }, [tableData])

  return (
    <>
      <Stack direction='row' alignItems='center'>
        {filterContainer()}
      </Stack>
      <TanstackTable
        columns={columns}
        data={tableData}
        initialTableState={initialTableState}
        onSortingChange={(sorting) => console.log(sorting)}
        isLoading={false}
        storageKey='licenseTableTableVisibility' />
      <div ref={loader} />
      <Outlet />
    </>
  )
}
