import React, { useContext, useMemo } from 'react';
import { IconButton, ButtonGroup, Tooltip, Input, Text, VStack, WrapItem, Button } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { EditIcon } from '@chakra-ui/icons';

import { TableData } from '../../components/TableData';
import { AppContext } from '../../contexts/AppContext';
import { useFetch } from '../../contexts/FetchContext';
import { IdentityRole } from '../../models/roles';

export const RolesTable = () => {
  const app = useContext(AppContext);
  const { backend } = useFetch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchItems = (params: any) => backend.role.getAllRoles(params);

  const formatDateTime = (dateTimeString: string): string => {
    const date = new Date(dateTimeString);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };

  
  const buttonGroup = (role: IdentityRole) => {
    return (
      <ButtonGroup variant="ghost">
        <Tooltip label="Edit Role">
          <IconButton
            aria-label="edit"
            icon={<EditIcon />}
            onClick={(event) => {
              event.stopPropagation();
              console.log(role.id, "roleidasdkasd")
              navigate(`/roles/${role.id}/edit`, { state: { previousLocation: location.pathname } });
            }}
          />
        </Tooltip>
      </ButtonGroup>
    );
  };

  const onRowClick = (role: IdentityRole) => {
      navigate(`/roles/${role.id}`, { state: { previousLocation: location.pathname } });
  };

  const columns: ColumnDef<IdentityRole>[] = [
    {
      id: 'name',
      accessorFn: row => row.name,
      header: 'Name',
      enableHiding: true,
    },
    {
      id: 'createdAt',
      accessorFn: row => formatDateTime(row.createdAt),
      header: 'Created At',
      enableHiding: true,
    },
    {
      id: 'createdBy',
      accessorFn: row => row.createdBy,
      header: 'Created By',
      enableHiding: true,
    },
    {
      id: 'lastModifiedAt',
      accessorFn: row => formatDateTime(row.lastModifiedAt),
      header: 'Last Modified At',
      enableHiding: true,
    },
    {
      id: 'lastModifiedBy',
      accessorFn: row => row.lastModifiedBy,
      header: 'Last Modified By',
      enableHiding: true,
    },
    {
      id: 'company',
      accessorFn: row => row.company,
      header: 'Company',
      enableHiding: true,
    },
  ];

  const additionalFilters = useMemo(() => (
    <>
      <WrapItem as={VStack} align="left">
        <Text fontWeight="bold">Search</Text>
        <Input
          placeholder="Search for roles"
          w="300px"
          value={searchParams.get('query') || ''}
          onChange={(e) => {
            const newParams = new URLSearchParams(searchParams);
            if (e.target.value) {
              newParams.set('query', e.target.value);
            } else {
              newParams.delete('query');
            }
            setSearchParams(newParams);
          }}
        />
      </WrapItem>
      <WrapItem>
        <Button colorScheme='blue' onClick={() => navigate('new')}>Create Role</Button>
      </WrapItem>
    </>
  ), [searchParams, setSearchParams]);

  return (
    <>
      <TableData<IdentityRole>
        title="Roles"
        fetchItems={fetchItems}
        columns={columns}
        initialSortColumn="name"
        sortDesc={false}
        statusOptions={[]}
        onRowClick={onRowClick}
        searchParams={searchParams}
        additionalFilters={additionalFilters}
        buttonGroup={buttonGroup}
      />
      <Outlet />
    </>
  );
};